import product1 from "../../img/product/female1.jpg";
import stars from "../../img/stars.png";
import Comments from "../comments";
const Women1 = () => {
  return (
    <section class="mt-4 mb-4">
      <div class="container-sm d-flex flex-column flex-lg-row justify-content-between gap-class align-self-stretc p-3">
        <div class="col-lg-5">
          <div
            id="carouselExampleAutoplaying"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src={product1} class="d-block w-100" alt="..." />
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div>
            <h4>French Terry Ruffled Dress</h4>
            <img class="w-25" src={stars} />
            <h3>₱1,500.00</h3>
            <p>LIMITED EDITION: Barbie™ Star Power</p>
          </div>
          <div>
            <p>Sizes</p>
            <select class="form-control form-control-sm w-50">
              <option value="" disabled selected hidden>
                Choose a Size
              </option>
              <option>2T</option>
              <option>3T-4T</option>
              <option>5T-6T</option>
              <option>7Y-8Y</option>
              <option>10Y-12Y</option>
            </select>
          </div>

          <div class="mt-3">
            <button type="button" class="btn btn-dark btn-lg w-50">
              Add to Cart
            </button>
          </div>
          <div class="mt-3">
            <h6>Product Details</h6>
            <p>
              Are you ready for fun? Barbie™ x Posh Peanut have joined forces
              again, and this collection is bolder- and groovier than ever!
              Channel your flower power with Barbie and friends, bright
              rainbows, and iconic pink hearts! Stock your dream closet with
              cozy PJs and the cutest dresses, tees, and leggings. There's
              something for every dreamer, with sizes ranging from NB to 12Y and
              XS to 3XL.
            </p>
            <h4>
              <strong>Features:</strong>
            </h4>
            <ul>
              <li>Relaxed fit dress</li>
              <li>Short cap sleeves with 3-tiered ruffle detail on shoulder</li>
              <li>Crew neckline</li>
              <li>Worn above the knee</li>
              <li>Breathable, lightweight, and stretchy</li>
              <li>
                Soft French Terry viscose from bamboo fabric (4x thicker than
                our signature Päpook® viscose from bamboo-blend fabric)
              </li>
              <li>95% Viscose from Bamboo, 5% Spandex</li>
              <li>Machine wash cold with like colors</li>
              <li>Tumble dry low / Cool iron if needed</li>
              <li>Signature Posh Peanut® design</li>
              <li>Matching tops sold separately</li>
            </ul>
          </div>
          <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  <h5>Care & Wash</h5>
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <p>
                    Most of our apparel is made from our signature Viscose from
                    Bamboo, and we suggest washing them on cold/gentle cycle
                    inside out. Lay flat to dry for optimal longevity or dry low
                    and cool iron if needed. Follow all care tag instructions.
                  </p>
                  <p>
                    Viscose is know to be buttery soft and the softer the
                    fabric, the more pilling is common — however, laying flat to
                    dry really keeps the fabric new. Most moms prefer to line
                    dry for optimal texture, lasting color, and to last through
                    the life span of the item.
                  </p>
                  <p>
                    Due to the delicate nature of our lush fabrics, please do{" "}
                    <strong>NOT </strong>wash with other items with zippers,
                    buttons, or hardware. Snagging may occur. Wash separate with
                    other babies items.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTwo">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              ></button>
            </h2>
            <div
              id="flush-collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div class="accordion-body">
                <p>
                  {" "}
                  Our signature Päpook® viscose from bamboo is lightweight,
                  breathable, and extra soft for your baby's delicate skin. Its
                  natural weave makes it stretchy enough for a nice snug fit,
                  yet comfortable for all-day wear.
                  <br />
                  <br />
                  <strong>No Harsh Chemicals</strong>
                  <br />
                  We comply with snug fitting standards to eliminate the use of
                  flame retardant chemicals during our production process –
                  irritants like chlorine-containing bleaches, zinc, and
                  sulfate. So you can rest easy knowing our fabric is free of
                  irritants and toxic treatments.
                  <br />
                  <br />
                  <strong>Benefits of Viscose from Bamboo</strong>
                  <br />
                  This fabric has it all. Lightweight and breathable with fibers
                  to wick away moisture while micro-gaps aid in ventilation and
                  heat regulation. This means you stay cooler during the summer
                  and a few degrees warmer during the winter. This makes it
                  great specifically for people with sensitive skin and/or
                  allergies. It also has natural UV protection to protect your
                  peanuts from sun rays in comfort.
                  <br />
                  <br />
                  <strong>NOT ALL FIBER IS CREATED EQUAL</strong>
                  <br />
                  <br />
                  At Posh Peanut, we do our bit towards fostering a more
                  positive future, creating thoughtfully produced garments.
                  Bamboo is one of the fastest-growing plants on earth. It
                  requires very little water and grows 3-4 feet a day without
                  any fertilizers. We are proud to say our products are made
                  using the highest standards in textile.
                </p>
              </div>
            </div>
          </div>
          <Comments />
        </div>
      </div>
    </section>
  );
};

export default Women1;
