import { useEffect, useState } from "react";
import myData from './comments.json';
const Comments = () => {
  const [Comments, setComments] = useState([]);
  const [newid, setNewId] = useState("");
  const [newcomment, setNewComment] = useState({});

  console.log(myData);

  // async function fetchComments() {
  //   const objcomments = await fetch("https://api.jsonserver.io/pp4/comment");
  //   const data = await objcomments.json();
  //   setNewId(data.length);
  //   setComments(data);
  // }
  // fetchComments();
  // function newComment(e) {
  //   newcomment[e.target.name] = e.target.value;
  //   setNewComment(newcomment);
  // }

  // async function addComment(e) {
  //   e.preventDefault();

  //   setNewComment({ ...newcomment, id: newid });

  //   const response = await fetch("https://api.jsonserver.io/pp4/comment", {
  //     method: "POST",
  //     mode: "cors",
  //     body: JSON.stringify(newcomment),
  //   });

  //   window.location.reload();
  // }

  return (
    <div class="mt-4">
      <div class="row d-flex flex-start">
        <div class="card text-body">
          <div class="card-body p-0">
            <h4 class="mb-0">Recent comments</h4>
            <p class="fw-light mb-4 pb-2">Latest Comments section by buyers</p>
            <div>
              {myData.comments.map((comment) => (
                <div class="d-flex flex-start">
                  <div>
                    <h6 class="fw-bold mb-1">{comment.Name}</h6>
                    <p class="mb-3">{comment.comment}</p>
                  </div>
                </div>
              ))}
            </div>
            <hr class="my-0" />
          </div>
        </div>
      </div>
      <div class="mt-3">
        <h4>Add Comments/Questions</h4>
        <form action="">
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">
              Name
            </label>
            <input
              type="email"
              class="form-control w-50"
              name="Name"
              value={newcomment.Name}
            />
          </div>
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">
              Comment
            </label>
            <textarea
              class="form-control"
              name="comment"
              id="exampleFormControlTextarea1"
              rows="3"
              value={newcomment.comment}
            ></textarea>
          </div>
          <button className="btn btn-dark">
            Add Comment
          </button>
        </form>
      </div>
    </div>
  );
};

export default Comments;
