import React from "react";
import "./App.css";
import Header from "./components/header";
import Home from "./components/home";
import Footer from "./components/footer";
import Contact from "./components/contact";
import Aboutus from "./components/aboutus";
import Mencol from "./components/menscollection";
import Womencol from "./components/womencol";
import Women1 from "./components/products/women1";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/contact-us" element={<Contact />} />
          <Route exact path="/about-us" element={<Aboutus />} />
          <Route exact path="/women-collections" element={<Womencol />} />
          <Route exact path="/men-collections" element={<Mencol />} />
          <Route exact path="/women1" element={<Women1 />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
